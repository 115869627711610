<template>
    <div>
        <!-- 通道管理》失败状态转义管理》失败状态转义策略管理 增加 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>{{cur_title}}</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
                <el-row v-show="this.prop_change_type == 'add'">
                    <el-form  :model="form_data" label-width="150px" style="margin-bottom: 12px;">
                        <el-form-item label="通道状态" >
                            <el-input v-model="form_data.state" placeholder="请填写通道状态" size="mini" ></el-input>
                        </el-form-item>

                        <el-form-item label="平台状态">
                            <el-select v-model="form_data.sync_state" filterable size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in channelStateUnifyList"
                                    :key="item.state"
                                    :label="item.state + '('+item.meaning+')'"
                                    :value="item.state">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="作用类型">
                            <el-select v-model="form_data.opt_type" size="mini" style="width:95px" @change="opt_type_change">
                                <el-option label="全局" value="0"></el-option>
                                <el-option label="通道公司" value="1"></el-option>
                                <el-option label="通道" value="2"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="通道公司" v-show="form_data.opt_type == '1'">
                            <el-select v-model="form_data.channel_company_id" filterable size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in channelCompanyList"
                                    :key="item.value"
                                    :label="item.name + (item.value > 0 ?  '('+item.value+')' : '')"
                                    :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="通道" v-show="form_data.opt_type == '2'">
                            <el-select v-model="form_data.channel_id" filterable size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in channelDataList"
                                    :key="item.id"
                                    :label="item.channel_name + (item.id > 0 ?  '('+item.id+')' : '')"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
                <el-row v-show="this.prop_change_type == 'upd'">
                    <el-form  :model="form_data_upd" label-width="150px" style="margin-bottom: 12px;">
                        <el-form-item label="通道状态" >
                            <el-input v-model="form_data_upd.state" placeholder="请填写状态" size="mini" disabled></el-input>
                        </el-form-item>

                        <el-form-item label="作用类型" >
                            <el-input v-model="form_data_upd.opt_type" size="mini" disabled></el-input>
                        </el-form-item>

                        <el-form-item label="平台状态">
                            <el-select v-model="form_data_upd.sync_state" filterable size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in channelStateUnifyList"
                                    :key="item.state"
                                    :label="item.state + '('+item.meaning+')'"
                                    :value="item.state">
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
import API from '../../api/api';

export default {
    props:{
        prop_change_i:0,//添加状态 组件 弹出框是否可见
        prop_change_type:"",//类型：添加或修改
        prop_change_item:{},//状态对象
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见
                if(this.prop_change_type === "add"){
                    this.cur_title = "增加失败状态转义策略";
                    //获取平台自定义失败状态列表
                    this.getChannelStateUnifyList();
                }else {
                    this.cur_title = "修改失败状态转义策略";
                    setTimeout(() => {
                        //获取数据
                        this.getData();
                    }, 1);   
                }
            }
        }
    },
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            cur_title:"",//当前页面的标题头

            form_data:{
                state:"",//状态
                sync_state:"",//转义状态 空字符表示全部
                opt_type:"0",//作用类型 0:全局 1:通道公司 2:通道
                channel_company_id:"",//通道公司id
                channel_id:"",//通道id
            },

            form_data_upd:{
                state_id:"",//状态id
                state:"",//状态
                sync_state:"",//转义状态 空字符表示全部
                opt_type:"",//作用类型 -1:全部 0:全局 1:通道公司 2:通道
            },

            channelStateUnifyList:[],//平台自定义失败状态列表
            channelDataList:[],//通道列表数据-请求接口获取
            channelCompanyList:[],//通道公司列表数据-请求接口获取
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

        //获取平台自定义失败状态列表
        this.getChannelStateUnifyList();

        //获取通道列表数据-请求接口获取
        this.getChannelDataList();
        //通道公司列表数据-请求接口获取
        this.getChannelCompanyList();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);  
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
         //查询
        getData(){
            this.form_data_upd.state_id = this.prop_change_item.id;//状态id
            this.form_data_upd.state = this.prop_change_item.state;//状态
            this.form_data_upd.opt_type = this.prop_change_item.show_content;//作用类型 -1:全部 0:全局 1:通道公司 2:通道
            this.form_data_upd.sync_state = this.prop_change_item.sync_state;//转义状态
        },
        //获取平台自定义失败状态列表
        getChannelStateUnifyList(){
            //请求接口
            API.ChannelStateUnifyServlet({
                param: "list"
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelStateUnifyList = res.list;
                }
            });
        },
        //获取通道列表数据-请求接口获取
        getChannelDataList(){
            //请求接口
            API.ChannelServlet({
                param: "listAISelect",
                search:"",//输入框填写的查询条件
                style:"-1",//通道属性 -2:包括国内国际全部,-1:国内全部 0:移动 1：联通 2：电信
                send_type:"-1",//通道发送短信的类型 -1:所有 1：验证码 2：行业通知 3：营销 4：四类
                onoff:"-1",//状态 -1：全部 0：关闭 1：打开
                agreement:"-1",//协议类型 -1:全部 0：无相关协议；1：http1.0;2:http2.0;3:助通协议;101:cmpp2.0 102:cmpp3.0 103:SGIP1.0 104:SGIP1.2 105:SMGP
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelDataList = res.list;//通道列表数据-指定通道组使用的--请求接口获取
                }
            });
        },
        //通道公司列表数据-请求接口获取
        getChannelCompanyList(){
            //请求接口
            API.ChannelCompanyServlet({
                param: "listAI"
            }).then((res) => {
                if (res.recode === 0) {
                    this.channelCompanyList = res.list//收款公司数据-请求接口获取
                }
            });
        },
         //作用类型切换
        opt_type_change(value,label){
            console.log('作用类型切换发生变化', value+"\t"+label);
            if(value == 0){
                this.form_data.channel_company_id = "";
                this.form_data.channel_id = "";
            }else if(value == 1){
                this.form_data.channel_id = "";
            }else if(value == 2){
                this.form_data.channel_company_id = "";
            }
        },
        //提交
        onSubmit(){
            var submit_obj = {};
            if(this.prop_change_type === "upd"){//修改
                if(API.isEmtry(this.form_data_upd.state)){
                    this.$message.error("请填写通道状态");
                    return;
                }
                if(API.isEmtry(this.form_data_upd.opt_type)){
                    this.$message.error("请选择作用类型");
                    return;
                }
                if(API.isEmtry(this.form_data_upd.sync_state)){
                    this.$message.error("请选择平台状态");
                    return;
                }
                submit_obj.param = "update";
                submit_obj.id = this.form_data_upd.state_id;
                submit_obj.sync_state = this.form_data_upd.sync_state;
            }else{//增加
                if(API.isEmtry(this.form_data.state)){
                    this.$message.error("请填写通道状态");
                    return;
                }
                if(API.isEmtry(this.form_data.sync_state)){
                    this.$message.error("请选择平台状态");
                    return;
                }
                if(API.isEmtry(this.form_data.opt_type)){
                    this.$message.error("请选择作用类型");
                    return;
                }
                if(this.form_data.opt_type == 1){
                    if(API.isEmtry(this.form_data.channel_company_id)){
                        this.$message.error("请选择通道公司");
                        return;
                    }
                }
                if(this.form_data.opt_type == 2){
                    if(API.isEmtry(this.form_data.channel_id)){
                        this.$message.error("请选择通道");
                        return;
                    }
                }
                submit_obj.param = "add";
                submit_obj.state = this.form_data.state;
                submit_obj.sync_state = this.form_data.sync_state;
                submit_obj.opt_type = this.form_data.opt_type;
                submit_obj.channel_company_id = this.form_data.channel_company_id;
                submit_obj.channel_id = this.form_data.channel_id;
            }
            
            API.ChannelStateSyncServlet(submit_obj).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },
    }
};
</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>